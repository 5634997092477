import { getProjectId } from 'jcb-token-kit'

export const linkType = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
}

export const linkTarget = {
  CURRENT: 1,
  NEW: 2,
}

export const resourceType = {
  QUEST: 'QUEST',
  QUESTION: 'QUESTION',
  USER: 'USER',
  FILE: 'FILE',
}

export const urlMap = {
  home: '/home',
  quest: '/quest',
  question: '/question',
  user: '/user',
  file: '/file',
}

export const MENU_OPTS = {
  BLANK: '_blank',
}

export const FORM_TYPE = {
  SELECT: 'select',
  TREE: 'tree',
  STANDARD: 'standard',
  NUMBER: 'number',
  STRING: 'string',
  BOOLEAN: 'boolean',
  TIME: 'time',
  IMAGE: 'image',
  FILE: 'file',
  MEDIA: 'media',
  MATRIX: 'matrix',
  JSON: 'json',
  BUTTON: 'button',
  VIDEO: 'video',
  TREESELECT: 'tree-select',
}

export const FORM_BOOL_TRUE = 'true'
export const FORM_BOOL_FALSE = 'false'

export const FORM_TIME_FORMAT = {
  DATE: 'date',
  DATETIME: 'date-time',
  TIME: 'time',
}

export const FORM_ITEM_LAYOUT = {
  HORIZON: 'horizon',
  VERTICAL: 'vertical',
}

// 暂用
export const FORM_ITEM_SPAN = {
  HORIZON: 18,
  VERTICAL: 20,
}

// 之前用作opts中的数据，现改作验证选项
export const CUSTOM_FORM_OPTS = {
  [FORM_TYPE.SELECT]: {
    MULTIPLE: 'multiple',
    ETC: 'etc',
    DROPDOWN: 'dropdown',
    LIST: 'list',
  },
  [FORM_TYPE.TREE]: {
    MULTIPLE: 'multiple',
    ASYNC: 'async',
  },
  [FORM_TYPE.STRING]: {
    MINLEN: 'minlen',
    MAXLEN: 'maxlen',
    FORMAT: 'format',
  },
  COMMON: {
    REQUIRED: 'required',
  },
}

export const CUSTOM_FORM_ERR_MSG = {
  [FORM_TYPE.STRING]: {
    [CUSTOM_FORM_OPTS[FORM_TYPE.STRING].MINLEN]: '字数不能少于{0}',
    [CUSTOM_FORM_OPTS[FORM_TYPE.STRING].MAXLEN]: '字数不能多于{0}',
    [CUSTOM_FORM_OPTS[FORM_TYPE.STRING].FORMAT]: '模式不匹配',
  },
  COMMON: {
    REQUIRED: '该项不能为空',
  },
}

export const CUSTOM_FORM_REQUIRED = {
  [FORM_TYPE.SELECT]: ['selects'],
  [FORM_TYPE.TREE]: ['nodes'],
}

export const FORM_SCHEMA_CONDITION = {
  EQUAL: 'equal',
  EXIST: 'exist',
  LT: 'lt',
  GT: 'gt',
  INCLUDE: 'include',
}

export const CONDITION_OPTS = {
  CONDITION: 'condition',
  OR: 'or',
  AND: 'and',
}

export const MSG_SHOW_TIME = 1.5

export const COMMON_MODAL_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export const OPEN_TYPE = {
  DRAWER: 'drawer',
  DIALOG: 'dialog',
  BLANK: 'blank',
  SELF: 'self',
}

export const SUBMIT_OPTS = {
  REFRESH: 'refresh',
  CLOSE: 'close',
}

export const PROJECT = {
  COMMON: 'COMMON',
  WJLJFL: 'WJLJFL',
}

export const ATTANDENCE_STATUS = {
  accept: '已通过',
  reject: '已拒绝',
  need_review: '待审核',
}

export const STAGE = {
  REVIEW: 'review',
  STREET_REVIEW: 'streetReview',
}

export const ROLE = {
  STREET: 'street',
  COMPANY: 'company',
}

export const OSS_PREFIX =
  'http://yuxi-wenming-public2.oss-cn-beijing.aliyuncs.com'

export const apiV1 = '/api/v1'
export const apiV2 = '/api/v2'
export const apiMock = '/api/mock'
export const apiLocal = '/api/local'
export const apiReport = '/api/report'
export const imgUrl = 'http://test.qyconsult.treeyee.com:3000/img/setting'

// const companyid = sessionStorage.getItem('companyid')
// const companyid = getCompanyId() || 0
// 返回调研工场工作台
export const backtextPro = 'http://sw.treeyee.com/user/workbench/home'

//  检查吧落地页登录
export const proLogin = `http://sw.treeyee.com/login?url=${encodeURIComponent(
  'http://pro.jianchaba.com/'
)}`

// 调研工场的落地页
export const Landingpage = 'http://sw.treeyee.com'
// 登录页
export const Loginpage = 'http://sw.treeyee.com/login'

export const downloadTest =
  'https://ugm.oss-cn-beijing.aliyuncs.com/ele_download/common/win32/%E4%B8%8B%E8%BD%BD%E5%99%A8%20Setup%201.1.3.exe'

export const downloadRelease =
  'https://ugm.oss-cn-beijing.aliyuncs.com/ele_download/common/production/%E4%B8%8B%E8%BD%BD%E5%99%A8%E6%AD%A3%E5%BC%8F%E7%89%88%20Setup%201.1.9.exe'

export const getProjectFun = {
  70: {
    allowMap: false,
    allowImport: true,
    allowDownload: false,
    allowMultiAction: false,
    allowPolygon: false,
    allowRePoints: false,
  },
  8: {
    allowMap: true,
    allowImport: false,
    allowDownload: true,
    allowMultiAction: true,
    allowPolygon: true,
    allowRePoints: true,
  },
  // 朝阳农委随手拍
  472: {
    allowMap: true,
    allowImport: true,
    allowDownload: true,
    allowMultiAction: true,
    allowPolygon: false,
    allowRePoints: false,
  },
  1238: {
    allowMap: true,
    allowImport: true,
    allowDownload: true,
    allowMultiAction: true,
    allowPolygon: false,
    allowRePoints: false,
  },
  1044: {
    allowMap: false,
    allowImport: false,
    allowDownload: true,
    allowMultiAction: true,
    allowPolygon: false,
    allowRePoints: false,
  },
  1255: {
    allowMap: true,
    allowImport: true,
    allowDownload: true,
    allowMultiAction: true,
    allowPolygon: false,
    allowRePoints: false,
  },
  1256: {
    allowMap: true,
    allowImport: true,
    allowDownload: true,
    allowMultiAction: true,
    allowPolygon: false,
    allowRePoints: false,
  },
  1259: {
    allowMap: true,
    allowImport: false,
    allowDownload: true,
    allowMultiAction: true,
    allowPolygon: false,
    allowRePoints: false,
  },
  default: {
    allowMap: true,
    allowImport: false,
    allowDownload: true,
    allowMultiAction: true,
    allowPolygon: false,
    allowRePoints: false,
  },
}

export const amapkey = '788e08def03f95c670944fe2c78fa76f'

export const QuestionTest = 'https://fangzhou.survey.work'
export const QuestionRelease = 'https://wj.survey.work'
export const JcbName =
  process.env.REACT_APP_TYPE === 'city'
    ? '实地检查-清研文明城市（区）创建智慧管理系统'
    : '调研工厂-明察暗访'

export const ucaseName = () => {
  if (['1351'].indexOf(getProjectId()) !== -1) {
    return '问题'
  }
  return '案件'
}
