import React, { useEffect } from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import {
  saveToken,
  getToken,
  getProjectMsg,
  refreshCompanyId,
  saveCompanyId,
  clearCompanyId,
  getCurrentCompanyId,
} from 'jcb-token-kit'
import NavMenu from 'jcb-nav-menu'
import { Spin } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'
import less from 'less'
import { getAsyncComponent, isNeedLoginPage } from '@utils'
import { JcbName } from '@utils/constant'
import favicon from '../../assets/images/favicon.ico'

const { useCustomized, usePage, useDomain } = NavMenu
const Layout = ({ location, history }) => {
  const { gotoLoginPage, getCompanyInfo } = useDomain()
  const { isLoading, getData, resources, getCompanySign } = useCustomized()
  const { updateTitle, updateFavicon, updateThemeColor } = usePage()
  const { token, companyid } = qs.parse(document.location.search.split('?')[1])
  if (token) {
    saveToken()
    if (companyid && companyid !== 0 && companyid !== '0') {
      refreshCompanyId()
    }
  }
  if (!token) {
    if (companyid && companyid !== 0 && companyid !== '0') {
      refreshCompanyId()
    }
  }
  if (getToken()) {
    axios.defaults.headers.common.token = getToken()
  } else if (token) {
    axios.defaults.headers.common.token = token
  }

  useEffect(() => {
    localStorage.removeItem('sign')
    localStorage.removeItem('dygchome')
    localStorage.removeItem('themeColor')
  }, [])

  useEffect(() => {
    if (location.pathname === '/') {
      if (getToken()) {
        getProjectMsg().then(({ data }) => {
          const { organizations = [] } = _.get(data, 0, {})
          const roleSlug = _.get(organizations, '0.roleSlug', '')
          sessionStorage.setItem('role', roleSlug)
          setTimeout(() => {
            history.push('/dashboard')
          }, 10)
        })
      }
      // else {
      //    history.push('/home')
      // }
    }
  }, [])

  useEffect(() => {
    if (companyid || companyid === 0) {
      if (companyid === 0 || companyid === '0') {
        clearCompanyId()
        getCompanyInfo().then((res) => {
          saveCompanyId(7, res.id + '')
          getData()
          getCompanySign()
        })
      } else {
        getData()
        getCompanySign()
      }
    }
  }, [companyid])

  useEffect(() => {
    if (!companyid && companyid !== 0 && token) {
      getCurrentCompanyId().then((data) => {
        if (data.id) {
          refreshCompanyId(data.id)
          getData()
          getCompanySign()
        }
      })
    } else {
      getData()
      getCompanySign()
    }
  }, [])

  useEffect(() => {
    if (isLoading) {
      if (!isNeedLoginPage() && !getToken()) {
        if (document.location.hostname === 'yuanlin.oopsdata.com') {
          window.location.href = '/login'
        } else {
          const fun = gotoLoginPage()
          if (fun) {
            fun.then((href_) => {
              if (href_) {
                window.location.href = href_
              }
            })
          }
        }
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (isLoading) {
      if (Object.keys(resources).length > 0) {
        updateTitle()
        updateFavicon()
      } else {
        updateTitle(JcbName)
        updateFavicon(favicon)
      }
    }
  }, [isLoading])
  // const addCssByLink = () => {
  //   let ele = document.getElementById('theme_less')
  //   if (!ele) {
  //     ele = document.querySelector('link[rel="stylesheet/less"]')
  //   }
  //   ele.href = 'theme.less'
  // }
  useEffect(() => {
    if (process.env.REACT_APP_TYPE === 'city') {
      less.modifyVars({
        '@primary-color': '#bd192d',
      })
    } else if (isLoading) {
      // if (localStorage.getItem('themeColor')) {
      //   addCssByLink()
      //   less.modifyVars({
      //     '@primary-color': localStorage.getItem('themeColor'),
      //   })
      // }
      updateThemeColor()
    }
  }, [isLoading])

  return !isLoading ? (
    <Spin />
  ) : (
    <Switch>
      <Redirect from="/" to="/dashboard" exact />
      <Route
        path="/login"
        component={getAsyncComponent(() => {
          return import('../commonLogin/customLogin')
        })}
      />
      <Route
        path="/dashboard"
        component={getAsyncComponent(() => {
          return import('./DashboardLayout')
        })}
      />
      <Route
        path="/projects/:id/yx_screen"
        exact
        component={getAsyncComponent(() => import('../Screen'))}
      />
      <Route
        path="/projects"
        component={getAsyncComponent(() => {
          return import('../../components/base/MainLayout')
        })}
      />
      {/* <Route
        path="/home"
        component={getAsyncComponent(() => import('../home/Index'))}
      /> */}
      <Route path="/app" component={getAsyncComponent(() => import('./App'))} />
      <Route
        path="/map"
        component={getAsyncComponent(() => import('../FreqPointMap/Map'))}
      />
      <Route path="/404" component={getAsyncComponent(() => import('./404'))} />
    </Switch>
  )
}

export default withRouter(Layout)
